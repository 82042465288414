<template>
  <base-section
    id="news"
    class="secondary"
  >
    <v-responsive
      class="mx-auto"
      max-width="1350"
    >
      <v-container
        v-if="newsLoaded"
        fluid
      >
        <v-carousel
          v-model="currPage"
          cycle
          interval="15000"
          height="400"
          hide-delimiter-background
          show-arrows-on-hover
        >
          <v-carousel-item
            v-for="(slide, i) in slides"
            :key="i"
          >
            <v-sheet
              height="100%"
            >
              <v-row
                class="fill-height"
                align="center"
                justify="center"
              >
                <!-- <v-col
                v-for="card in cards"
                :key="card.title"
                cols="12"
                sm="4"
                md="3"
              >
                <base-info-card
                  align="center"
                  dark
                  v-bind="card"
                />
              </v-col> -->
                <v-col
                  v-for="article in slide"
                  :key="article.id"
                  class="fill-height"
                  cols="12"
                  sm="4"
                  md="3"
                >
                  <base-news-card
                    align="center"
                    dark
                    v-bind="article"
                  />
                </v-col>
              </v-row>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>
      </v-container>
    </v-responsive>
  </base-section>
</template>

<script>
  import * as api from '@/functions/api.js'

  export default {
    name: 'SectionFeatures',

    data: () => ({
      newsLoaded: false,
      news: [],
      slides: [],
      currPage: 0,
      pageSize: 4,
      cards: [
        {
          icon: 'mdi-keyboard-outline',
          title: 'News Article 1',
          text: 'Efficiently unleash media information without cross-media value. Quickly maximize value timely deliverables schemas.',
        },
        {
          icon: 'mdi-camera-outline',
          title: 'News Article 2',
          text: 'Efficiently unleash media information without cross-media value. Quickly maximize value timely deliverables schemas.',
        },
        {
          icon: 'mdi-pencil-outline',
          title: 'News Article 3',
          text: 'Efficiently unleash media information without cross-media value. Quickly maximize value timely deliverables schemas.',
        },
        {
          icon: 'mdi-puzzle-outline',
          title: 'News Article 4',
          text: 'Efficiently unleash media information without cross-media value. Quickly maximize value timely deliverables schemas.',
        },
      ],
    }),
    mounted () {
      this.initialize()
    },
    methods: {
      initialize () {
        api.getAll('news/active/').then(results => {
          // console.log(results.data)
          this.news = results.data
          // break up the news into slides
          var LastSlideCount = this.news.total % this.pageSize
          var slideCount = parseInt(this.news.total / this.pageSize)
          for (var i = 0; i < slideCount; ++i) {
            this.slides.push(this.news.results.slice(i * this.pageSize, (i + 1) * this.pageSize))
          }
          // Now, push the remainder
          if (LastSlideCount > 0) {
            this.slides.push(this.news.results.slice(slideCount * this.pageSize))
          }
          // console.log(this.slides);
          this.newsLoaded = true
        })
      },
    },
  }
</script>
